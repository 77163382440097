import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.addEventListener("load", () => {
	new SiteHeader();
	new FloatLabels();

	toggleMenu();

	if (isMobile()) {
		closeMenu();
	}
});

window.onresize = () => {
	if (isMobile()) {
		closeMenu();
	}
};
