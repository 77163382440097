export const getScreenWidth = () => {
	let w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName("body")[0],
		width = w.innerWidth || e.clientWidth || g.clientWidth;

	return width;
};

export const switchImage = (offset) => {
	const currentImage = document.querySelector(".site-branding img");
	const currentImageSrc = currentImage.getAttribute("src");
	let replaceImageForOriginal;

	if (offset > 0) {
		replaceImageForOriginal = currentImageSrc.replace("white", "original");
	} else {
		replaceImageForOriginal = currentImageSrc.replace("original", "white");
	}

	currentImage.setAttribute("src", replaceImageForOriginal);
};

export const getScreenHeight = () => {
	let w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName("body")[0],
		height = w.innerHeight || e.clientHeight || g.clientHeight;

	return height;
};

export const isMobile = () => {
	if (getScreenWidth() <= 768) return true;
};

export const isPhone = () => {
	if (getScreenWidth() <= 440) return true;
};

export const getCoords = (elem) => {
	let box = elem.getBoundingClientRect();

	let body = document.body;
	let docEl = document.documentElement;

	let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
	let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

	let clientTop = docEl.clientTop || body.clientTop || 0;
	let clientLeft = docEl.clientLeft || body.clientLeft || 0;

	let top = box.top + scrollTop - clientTop;
	let left = box.left + scrollLeft - clientLeft;

	return { top: Math.round(top), left: Math.round(left) };
};
